<template>
  <div>

    <b-modal
      id="modal-doctor"
      size="xl"
      title="Pilih Dokter"
      hide-footer
    >
      <b-tabs content-class="mt-4">
        <b-tab
          title="Daftar Dokter"
          active
        >
          <Table
            purpose="modal"
            :items="items"
            :fields="fields"            
            @chooseDoctor="chosenDoctor"
          />
        </b-tab>
        <b-tab title="Tambah Baru">
          <b-form @submit.stop.prevent="customDoctorFormOnSubmit">
            <b-form-group
              id="input-group-name"
              label="Nama Dokter:"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                v-model="form.name"
                placeholder="Nama Dokter"
                required
              ></b-form-input>
            </b-form-group>
            <!-- Button -->
            <b-button
              type="submit"
              variant="primary"
            >Simpan</b-button>
            <b-button
              class="ml-2"
              variant="default"
              @click="$bvModal.hide('modal-doctor')"
            >
              Batal
            </b-button>
          </b-form>
        </b-tab>
      </b-tabs>
    </b-modal>

  </div>
</template>

<script>

import Table from '@/component/doctors/Table.vue'

export default {

  components: {
    Table
  },

  data() {
    return {
      // pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "number",
          label: '#',
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "mobile_phone",
          label: "Nomor Telepon",
          sortable: true,
        },
        {
          key: "polyclinic_name",
          label: "PoliKlinik",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // Form
      form: {
        name: ''
      }
    }
  },

  methods: {

    chosenDoctor(value) {
      this.$emit('chosenDoctor', value)
    },

    customDoctorFormOnSubmit() {
      this.$emit('customSubmitedDoctor', this.form.name)
    }

  }

}
</script>

<style>
</style>