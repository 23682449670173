<template>
  <div>

    <div class="row mb-2 mt-n4 no-gutters mx-n1">

      <div class="col-xl-8 col-md-7 col-12 px-2 py-lg-0 py-1">
        <div class="card">
          <div class="row p-2">

            <!-- Filter By Category -->
            <div class="col-md-8">
              <b-nav pills class="nav-category custom-scroller">
                <b-nav-item
                  :active="navActive == null"
                  @click="navCategoryAllOnClick">
                  Semua
                </b-nav-item>
                <b-nav-item
                  v-for="(category, index) in medicineCategories"
                  :key="category.id"
                  :active="navActive == index"
                  @click="navCategoryOnClick(index, category.id)">
                  {{ category.name }}
                </b-nav-item>
              </b-nav>
            </div>

            <!-- Filter By Name -->
            <div class="col-md-4">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text squared>
                    <b-icon-search></b-icon-search>
                  </b-input-group-text>
                </template>
                <b-form-input
                  type="text"
                  v-model="filter.search"
                  placeholder="Cari Obat"
                  @keyup="inputSearchOnKeyUp"></b-form-input>
              </b-input-group>
            </div>

          </div>
        </div>
      </div>

      <div class="col-xl-4 col-md-5 col-12 px-2 py-lg-0 py-1">
        <div class="row no-gutters mx-n1">
          <div class="col-md py-lg-0 py-1 px-1">
            <b-button
              squared
              class="btn btn-lg btn-block btn-info modal-trigger"
              v-b-modal.modal-patient>
              {{ display.patient_name }}
            </b-button>
          </div>
          <div class="col-md py-lg-0 py-1 px-1">
            <b-button
              squared
              class="btn btn-lg btn-block btn-info modal-trigger"
              v-b-modal.modal-doctor>
              {{ display.doctor_name }}
            </b-button>
          </div>
        </div>
      </div>

    </div>

    <div class="row no-gutters mx-n1">

      <div class="col-xl-8 col-md-7 col-12 px-2" ref="medicineScroller" style="overflow-y: auto"
        @scroll="medicineOnScroll">
        <div class="row no-gutters mx-n1" style="height: calc(100vh - 400px)">
          <div
            v-for="medicine in medicines"
            :key="medicine.id"
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 p-2">
            <MedicineCard
              :data="medicine"
              @cardOnClick="cardMedicineOnClick" />
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-md-5 col-12 px-2">
        <div class="card shadow-sm mb-2" style="min-height: calc(100vh - 345px)">
          <div class="card-body py-2 px-3" style="height: 100%; overflow-y: auto">
            <div class="list-group list-group-flush">
              <div
                class="d-flex justify-content-between align-items-center py-2"
                v-for="(item, index) in form.items"
                :key="item.medicine_id">

                <div class="text-truncate" style="font-size: 13px; width: 100px">{{ item.medicine_name }}</div>
                <div class="btn-group btn-group-sm">
                  <b-button
                    squared
                    class="btn btn-sm btn-info square"
                    @click="btnMinQtyOnClick(item, index)"><i class="fas fa-sm fa-fw fa-minus"></i></b-button>
                  <span><button class="btn btn-sm btn-block bg-white">{{ item.quantity }}</button></span>
                  <b-button
                    squared
                    class="btn btn-sm btn-info"
                    @click="btnAddQtyOnClick(item, index)"><i class="fas fa-sm fa-fw fa-plus"></i></b-button>
                </div>
                <div class="text-right">
                  <div class="font-weight-bold">{{ parseInt(parseInt(item.amount) * parseInt(item.quantity)).toLocaleString("id-ID") }}</div>
                  <div v-if="item.promo_amount > 0">
                    <small>- {{ parseInt(parseInt(item.promo_amount) * parseInt(item.quantity)).toLocaleString("id-ID") }} </small>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="card-footer py-2 px-3 bg-white">
            <div class="d-flex justify-content-between">
              <div>Subtotal</div>
              <div class="font-weight-bold">{{ parseInt(form.total_amount).toLocaleString('id-ID') }}</div>
            </div>
            <div class="d-flex justify-content-between">
              <div>Potongan Promo</div>
              <div class="font-weight-bold">
                {{ parseInt(form.total_promo_amount).toLocaleString("id-ID") }}
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div>Total</div>
              <div class="font-weight-bold">{{ parseInt(form.final_amount).toLocaleString('id-ID') }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row no-gutters mx-n1">
      <div class="col-md px-2 offset-8">
        <div class="row no-gutters mx-n1">
          <div class="col-md-10 px-1">
            <button
              class="btn btn-lg btn-block btn-success"
              @click="btnCheckoutOnClick"><i class="fas fa-fw fa-cash-register"></i>Bayar</button>
          </div>
          <div class="col-md-2 px-1">
            <button
              class="btn btn-lg btn-block btn-outline-danger"
              @click="btnResetOnClick"><i class="fas fa-fw fa-sync-alt"></i></button>
          </div>
        </div>
      </div>
    </div>

    <ModalCheckout
      :form="form"
      :error="error"
      :coupon="coupon"
      :isHospitalTransaction="false"
      @couponOnDismissed="couponOnDismissed"
      @paymentMethodOnClick="paymentMethodOnClick"
      @btnShortCutOnClick="btnShortCutOnClick"
      @inputCashOnKeyUp="inputCashOnKeyUp"
      @btnEWalletOnClick="btnEWalletOnClick"
      @modalCheckoutOnHide="modalCheckoutOnHide"
      @confirmCheckout="confirmCheckout" />

    <ModalPatient
      :isCashier="true"
      @chosenPatient="setPatient"
      @submitedPatient="setPatient"
      @customSubmitedPatient="customSubmitedPatient" />

    <ModalDoctor
      @chosenDoctor="setDoctor"
      @submitedDoctor="setDoctor"
      @customSubmitedDoctor="customSubmitedDoctor" />

    <!-- Modal Coupon -->
    <ModalCoupon @chosenCoupon="modalCouponOnSelect" />

    <div class="d-none">
      <Print
        :items="items"
        :data="data" />
    </div>

  </div>
</template>

<script>

// components
import Print from '@/component/pharmacy/pharmacy-orders/Print.vue'
import ModalCoupon from "@/component/general/ModalCoupon.vue"
import MedicineCard from '@/component/pharmacy/cashier/MedicineCard.vue'
import ModalPatient from '@/component/general/ModalPatient.vue'
import ModalCheckout from '@/component/general/ModalCheckout.vue'
import ModalDoctor from '@/component/general/ModalDoctor.vue'

import { debounce } from "@/core/modules/Helper.js"
import axios from "axios"
import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    ModalCoupon,
    MedicineCard,
    ModalPatient,
    ModalCheckout,
    ModalDoctor,
    Print
  },

  data() {
    return {
      // Display Data
      display: {
        patient_name: 'Pilih Pasien',
        doctor_name: 'Pilih Dokter'
      },
      // Medicines
      medicines: [],
      medicineCategories: [],
      // coupon
      coupon: null,
      // Form
      form: {
        doctor_id: '',
        doctor_name: '',
        patient_id: '',
        patient_name: '',
        payment_method_id: 1,
        bank_id: '',
        coupon_id: null,
        coupon_name: '',
        coupon_is_percent: 0,
        coupon_percentage_amount: 0,
        coupon_amount: 0,
        card_number: '',
        card_type: '',
        edc_struct: '',
        e_wallet_id: '',
        surcharge: 0,
        total_amount: 0,
        total_promo_amount: 0,
        tax_amount: 0,
        final_amount: 0,
        payment_amount: 0,
        change_amount: 0,
        notes: '',
        items: []
      },
      error: {
        bank_id: '',
        card_number: '',
        card_type: '',
        edc_struct: '',
      },
      // Filter
      filter: {
        medicine_category_id: '',
        search: ''
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRow: 0,
      // Print Data
      items: [],
      data: {},
      // Others
      navActive: null
    }
  },

  methods: {

    navCategoryAllOnClick() {
      this.navActive = null
      this.filter.medicine_category_id = ''
      this.currentPage = 1
      this.scrollMedicineToTop()
      this.getMedicine()
    },

    modalCouponOnSelect(value) {
      const finalAmountBeforeCoupon = this.form.final_amount + this.form.coupon_amount
      const newCouponAmount = this.getCouponAmount(true, value)
      // validate coupon amount
      if (finalAmountBeforeCoupon <= 0 || finalAmountBeforeCoupon < newCouponAmount) {
        Swal.fire('Pemberitahuan', 'Kupon tidak boleh melebihi nominal transaksi', 'warning')
        return
      }
      this.$bvModal.hide("modal-coupon")
      this.coupon = value
      this.form.coupon_id = value.id
      this.form.coupon_name = value.name
      this.form.coupon_is_percent = value.is_percent
      let couponPercentageAmount = 0
      if (value.is_percent == 1)
        couponPercentageAmount = value.amount
      this.form.coupon_percentage_amount = couponPercentageAmount
      // restore original final amount
      this.form.final_amount = finalAmountBeforeCoupon
      // set coupon amount
      this.form.coupon_amount = newCouponAmount
      // re calculate final amount
      this.form.final_amount = this.form.final_amount - newCouponAmount
    },

    getCouponAmount(processed = false, value = null) {
      let coupon = value ? value : this.coupon
      let couponAmount = 0
      if (coupon) {
        if (coupon.is_percent == 1) {
          if (!processed)
            couponAmount = `${parseInt(coupon.amount)}%`
          else {
            couponAmount = parseInt((this.form.final_amount + this.form.coupon_amount) * (coupon.amount / 100))
            if (couponAmount > coupon.max_amount && coupon.max_amount > 0)
              couponAmount = coupon.max_amount
          }
        } else
          couponAmount = parseInt(coupon.amount)
      }
      return couponAmount
    },

    couponOnDismissed() {
      this.coupon = null
      // reset final amount
      this.form.final_amount = this.form.final_amount + this.form.coupon_amount
      // reset coupon
      this.form.coupon_id = null
      this.form.coupon_name = ''
      this.form.coupon_is_percent = 0
      this.form.coupon_percentage_amount = 0
      this.form.coupon_amount = 0
    },

    setPatient(value) {
      this.form.patient_id = value.id
      this.display.patient_name = `Pasien: ${value.name}`
      this.$bvModal.hide('modal-patient')
    },

    customSubmitedPatient(value) {
      this.form.patient_name = value
      this.display.patient_name = `Pasien: ${value}`
      this.$bvModal.hide('modal-patient')
    },

    setDoctor(value) {
      this.form.doctor_id = value.id
      this.display.doctor_name = `Dokter: ${value.name}`
      this.$bvModal.hide('modal-doctor')
    },

    customSubmitedDoctor(value) {
      this.form.doctor_name = value
      this.display.doctor_name = `Dokter: ${value}`
      this.$bvModal.hide('modal-doctor')
    },

    resetForm() {
      this.form = validation.clearForm(this.form)
      this.couponOnDismissed()
      this.form.items = []
      this.form.payment_method_id = 1
      this.form.total_amount = 0
      this.form.total_promo_amount = 0
      this.form.final_amount = 0
      this.form.change_amount = 0
      this.form.tax_amount = 0
      this.form.surcharge = 0
      this.display.patient_name = 'Pilih Pasien'
      this.display.doctor_name = 'Pilih Dokter'
      this.data = {}
      this.items = []
      this.error = validation.clearValidationError(this.error)
    },

    btnResetOnClick() {
      Swal.fire({
        title: 'Konfirmasi',
        text: 'Reset Order?',
        showCancelButton: true,
        icon: 'question',
      }).then((result) => {
        if (result.value) {
          this.resetForm()
        }
      })
    },

    scrollMedicineToTop() {
      this.$refs.medicineScroller.scrollTo({ top: 0, behavior: 'smooth' })
    },

    async getMedicine(isScrolled = false) {
      let slug = `?medicine_category_id=${this.filter.medicine_category_id}&search=${this.filter.search}&with_promo=true`
      let paging = `&paginate=true&page=${this.currentPage}&per_page=${this.perPage}`
      const response = await axios.get(`medicines/get-for-cashier${slug}${paging}`)
      if (response.status == 200) {
        const result = response.data
        // set total row
        if (this.totalRow == 0)
          this.totalRow = result.total_row
        // set medicine
        if (isScrolled) {
          if (result.data.length < this.totalRow)
            this.medicines = this.medicines.concat(result.data)
        } else {
          this.medicines = result.data
        }
      }
    },

    async getMedicineCategories() {
      this.medicineCategories = await module.list('medicine-categories')
    },

    navCategoryOnClick(index, id) {
      this.navActive = index
      this.filter.medicine_category_id = id
      this.currentPage = 1
      this.scrollMedicineToTop()
      this.getMedicine()
    },

    inputSearchOnKeyUp() {
      debounce(() => { this.getMedicine() }, 500)
    },

    medicineOnScroll(e) {
      let target = e.target
      if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
        this.currentPage++
        this.getMedicine(true)
      }
    },

    btnAddQtyOnClick(item, index) {
      let items = this.form.items, found = items[index], quantity = found.quantity
      if (quantity >= found.stock) {
        Swal.fire({
          title: 'Pemberitahuan',
          text: 'Jumlah Melebihi Ketersediaan Stock!',
          icon: 'warning',
          showConfirmButton: false,
          timer: 1800,
          timerProgressBar: true
        })
        return
      }
      found.quantity += 1
      found.subtotal = found.amount * found.quantity
      this.form.total_amount += parseInt(found.amount)
      this.form.total_promo_amount += parseInt(found.promo_amount)
      this.form.final_amount = this.form.total_amount - this.form.total_promo_amount
    },

    btnMinQtyOnClick(item, index) {
      let items = this.form.items, found = items[index], quantity = found.quantity
      if (quantity == 1) {
        quantity = 0
        items.splice(index, 1)
      } else {
        found.quantity -= 1
        found.subtotal = found.amount * found.quantity
      }
      this.form.total_amount -= parseInt(found.amount)
      this.form.total_promo_amount -= parseInt(found.promo_amount)
      this.form.final_amount = this.form.total_amount - this.form.total_promo_amount
    },

    cardMedicineOnClick(item, promoAmount) {
      if (item.stock == 0) {
        Swal.fire('Peringatan', 'Stok Habis', 'warning')
        return
      }
      let exist = false
      let medicineId
      let quantity = 0
      let items = this.form.items
      items.forEach(element => {
        if (element.medicine_id == item.id) {
          medicineId = element.medicine_id
          quantity = element.quantity
          exist = true
        }
      })
      if (exist) {
        let found = items.findIndex(x => x.medicine_id == medicineId)
        if (items[found].quantity >= items[found].stock) {
          Swal.fire({
            title: 'Pemberitahuan',
            text: 'Jumlah Melebihi Ketersediaan Stock!',
            icon: 'warning',
            showConfirmButton: false,
            timer: 1800,
            timerProgressBar: true
          })
          return
        }
        items[found].quantity = quantity += 1
        items[found].subtotal = parseInt(quantity * items[found].amount)
        this.form.total_amount += parseInt(item.sales_price)
        this.form.total_promo_amount += parseInt(items[found].promo_amount)
        this.form.final_amount = this.form.total_amount - this.form.total_promo_amount + this.form.tax_amount
      } else {
        items.push({
          medicine_id: item.id,
          promo_id: item.promo_id,
          medicine_name: item.name,
          quantity: 1,
          amount: item.sales_price,
          promo_amount: promoAmount,
          subtotal: item.sales_price,
          stock: item.stock,
          notes: "",
          promo: item.promo
        })
        this.form.total_amount += parseInt(item.sales_price)
        this.form.total_promo_amount += parseInt(promoAmount)
        this.form.final_amount = this.form.total_amount - this.form.total_promo_amount + this.form.tax_amount
      }
    },

    modalCheckoutOnHide() {
      this.error = validation.clearValidationError(this.error)
      this.couponOnDismissed()
      this.form.payment_amount = 0
      this.form.change_amount = 0
    },

    paymentMethodOnClick(id) {
      this.form.notes = ''
      this.form.payment_method_id = id
    },

    btnCheckoutOnClick() {
      if (this.form.items.length == 0) {
        Swal.fire({
          title: 'Peringatan',
          text: 'Anda Belum Memilih Obat',
          icon: 'warning'
        })
        return
      }
      this.$bvModal.show('modal-checkout')
    },

    btnShortCutOnClick(amount) {
      if (amount != 0) {
        this.form.payment_amount = parseInt(amount).toLocaleString("id-ID")
        this.form.change_amount = parseInt(this.form.payment_amount.toString().replace(/\D/g, ""), 10) - this.form.final_amount
      } else {
        this.form.payment_amount = parseInt(this.form.final_amount).toLocaleString('id-ID')
        this.form.change_amount = 0
      }
    },

    btnEWalletOnClick(id) {
      this.form.e_wallet_id = id
    },

    inputCashOnKeyUp() {
      this.form.change_amount = parseInt(this.form.payment_amount.toString().replace(/\D/g, ''), 10) - this.form.final_amount
    },

    async confirmCheckout() {
      if (parseInt(this.form.payment_amount.toString().replace(/\D/g, ''), 10) < this.form.final_amount && this.form.payment_method_id == 1) {
        Swal.fire({
          title: 'Peringatan',
          text: 'Uang Tidak Cukup',
          icon: 'warning'
        })
      } else {
        let response = await module.submit(this.form, 'pharmacy-orders/checkout')
        if (response.state == 'error') {
          this.error = validation.setValidationError(this.error, response.error.errors)
        } else {
          Swal.fire({
            title: response.success.title,
            text: response.success.message,
            icon: 'success',
          }).then((result) => {
            if (result.value) {
              this.$router.push(`/pharmacy/sales/detail/${response.success.order.id}`)
            }
          })
        }
      }
    }

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Apotek", route: "" },
      { title: "Tambah Penjualan" }
    ])
    this.getMedicineCategories()
    this.getMedicine()
  }

}
</script>

<style scoped>
/* width */
.nav-category::-webkit-scrollbar {
  width: 3px !important;
  height: 5px !important;
}

/* Track */
.custom-scroller::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(221, 221, 221) !important;
  border-radius: 10px !important;
}

/* Handle */
.custom-scroller::-webkit-scrollbar-thumb {
  background: #0BB7AF !important;
  border-radius: 10px !important;
}

/* Handle on hover */
.custom-scroller::-webkit-scrollbar-thumb:hover {
  background: #0BB7AF !important;
}

.custom-scroller {
  display: -webkit-box;
  white-space: nowrap !important;
  overflow-x: auto !important;
  scrollbar-width: none;
}

.modal-trigger {
  height: 54.23px;
  font-size: 13px;
}

.nav-category li {
  display: inline-block !important;
  float: none !important;
}
</style>