<template>
  <div>

    <div class="card shadow-sm card-medicine" @click="cardOnClick(data, getPromoAmount(data.promo, data.sales_price))" style="border: none">
      <div class="d-flex justify-content-center align-items-center image-container">
        <div style="height: 110px; width: 110px; position: relative">
          <img class="image" :src="data.photo" alt="">
        </div>
      </div>
      <div class="px-3 pt-1" style="min-height: 75px">
        <div class="card-text text-truncate" style="font-size: 14px">{{ data.name }}</div>
        <div class="d-flex justify-content-between align-items-center mt-n1">
          <div class="text-primary text-hover-success" style="font-size: 14px">
            <div class="font-weight-bolder">
              Rp {{ parseInt(parseInt(data.sales_price) - getPromoAmount(data.promo, data.sales_price)).toLocaleString('id-ID') }}
            </div>
            <span v-if="data.promo">
              <b-button size="sm" class="p-1 mr-1 bg-light-danger text-danger font-weight-bolder">
                - {{ getPromoInfo(data) }}
              </b-button>
              <small><s>{{ parseInt(data.sales_price).toLocaleString('id-ID') }}</s></small>
            </span>
          </div>
          <div style="font-size: 13px">{{ data.stock }} Pcs</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import { getPromoAmount } from "@/core/modules/Helper.js"

export default {

  props: {
    data: Object
  },

  methods: {

    cardOnClick(data, promoAmount) {
      this.$emit('cardOnClick', data, promoAmount)
    },

    getPromoInfo(item) {
      let result = ``
      if (item.promo) {
        if (item.promo.is_percent == 1)
          result = `${parseInt(item.promo.amount)}%, ${parseInt(item.sales_price * item.promo.amount / 100).toLocaleString('id-ID')}`
        else
          result = parseInt(item.promo.amount).toLocaleString('id-ID')
      }
      return result
    },

    getPromoAmount(promo, amount) {
      return getPromoAmount(promo, amount)
    }

  },

  mounted() {

  }

}
</script>

<style scoped>
.card-medicine {
  transition: all ease-in-out 0.3s;
}

.card-medicine:hover {
  box-shadow: 4px 4px 4px rgba(175, 175, 175, 0.527) !important;
  cursor: pointer;
}
</style>